import React, { useState, useEffect } from 'react';
import { Search, Mail, FileText, FileSpreadsheet } from 'lucide-react';
import { Button, TextField, Card, CardContent, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const EyeNetWorthMain = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('address');
  const [searchResults, setSearchResults] = useState(null);
  const [searchCount, setSearchCount] = useState(0);
  const [showPaymentPrompt, setShowPaymentPrompt] = useState(false);

  useEffect(() => {
    // Load search count from localStorage on component mount
    const savedSearchCount = localStorage.getItem('searchCount');
    if (savedSearchCount) {
      setSearchCount(parseInt(savedSearchCount, 10));
    }
  }, []);

  const handleSearch = async () => {
    if (searchCount >= 5) {
      setShowPaymentPrompt(true);
      return;
    }

    console.log(`Searching for ${searchType}: ${searchQuery}`);
    // Perform the search operation here
    setSearchResults({
      fullName: 'Mark Cuban',
      location: 'Dallas, Texas',
      purchaseDate: '2020-01-01',
      firstMortgageAmount: 5000000,
      interestRate: 3.5,
      estimatedValue: 10000000,
      recentMortgageAmount: 6000000,
      equity: 4000000
    });

    // Increment search count and save to localStorage
    const newSearchCount = searchCount + 1;
    setSearchCount(newSearchCount);
    localStorage.setItem('searchCount', newSearchCount.toString());
  };

  const handlePayment = () => {
    // Implement payment logic here
    console.log('Processing payment...');
    // After successful payment, you might reset the search count
    setSearchCount(0);
    localStorage.setItem('searchCount', '0');
    setShowPaymentPrompt(false);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh', 
      maxWidth: '800px', 
      margin: '0 auto', 
      padding: '20px',
      boxSizing: 'border-box',
    }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <Typography variant="h4" gutterBottom>EyeNetWorth</Typography>
        
        <Box sx={{ display: 'flex', marginBottom: '20px' }}>
          <TextField
            variant="outlined"
            placeholder={searchType === 'address' ? 'Enter address' : 'Enter name'}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ flexGrow: 1, marginRight: '10px' }}
          />
          <Button variant="contained" onClick={handleSearch} startIcon={<Search />}>
            Search
          </Button>
        </Box>
        
        <Box sx={{ marginBottom: '20px' }}>
          <Button
            variant={searchType === 'address' ? 'contained' : 'outlined'}
            onClick={() => setSearchType('address')}
            sx={{ marginRight: '10px' }}
          >
            Search by Address
          </Button>
          <Button
            variant={searchType === 'name' ? 'contained' : 'outlined'}
            onClick={() => setSearchType('name')}
          >
            Search by Name
          </Button>
        </Box>

        <Typography variant="body2" sx={{ marginBottom: '20px' }}>
          Searches remaining: {Math.max(5 - searchCount, 0)}
        </Typography>

        {searchResults && (
          <Card sx={{ marginBottom: '20px' }}>
            <CardContent>
              <Typography variant="h6">{searchResults.fullName}</Typography>
              <Typography>Location: {searchResults.location}</Typography>
              <Typography>Purchase Date: {searchResults.purchaseDate}</Typography>
              <Typography>First Mortgage: ${searchResults.firstMortgageAmount} at {searchResults.interestRate}%</Typography>
              <Typography>Estimated Value: ${searchResults.estimatedValue}</Typography>
              <Typography>Recent Mortgage: ${searchResults.recentMortgageAmount}</Typography>
              <Typography>Equity: ${searchResults.equity}</Typography>
            </CardContent>
          </Card>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" startIcon={<Mail />} sx={{ marginRight: '10px' }}>
            Email Results
          </Button>
          <Button variant="outlined" startIcon={<FileText />} sx={{ marginRight: '10px' }}>
            Export to PDF
          </Button>
          <Button variant="outlined" startIcon={<FileSpreadsheet />}>
            Export to Excel
          </Button>
        </Box>
      </Box>

      <Box component="footer" sx={{ 
        marginTop: 'auto', 
        paddingTop: '20px',
        borderTop: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          EyeNetWorth Inc.
        </Typography>
        <Box>
          <Button href="/about" sx={{ marginRight: '10px' }}>About</Button>
          <Button href="/terms" sx={{ marginRight: '10px' }}>Terms</Button>
          <Button href="/privacy" sx={{ marginRight: '10px' }}>Privacy</Button>
          <Button href="/blog">Blog</Button>
        </Box>
      </Box>

      <Dialog open={showPaymentPrompt} onClose={() => setShowPaymentPrompt(false)}>
        <DialogTitle>Search Limit Reached</DialogTitle>
        <DialogContent>
          <Typography>
            You have reached the limit of 5 free searches. Would you like to make a payment to continue searching?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPaymentPrompt(false)}>Cancel</Button>
          <Button onClick={handlePayment} variant="contained">Make Payment</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EyeNetWorthMain;